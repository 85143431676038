import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import { Link } from 'gatsby'
import './productGallery.scss'
import MachineNavigation from './MachineNavigation'

export default function ProductGallery(props) {
    let machine = props.pageContext
    return (
        <Layout>
            <MachineNavigation machine={machine} page="gallery"/>
            <PaddedLayout>
                <h4>{machine.model} </h4>
            <h2>Photo Gallery</h2>
            <div className="gallery-navigation">
                <ul>
                    <li><Link to="">All</Link></li>
                    <li><Link to="">Exterior</Link></li>
                    <li><Link to="">Interior</Link></li>
                </ul>
            </div>
            <section className="gallery-exterior">
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            <div className="image-wrapper"></div>
            </section>
            </PaddedLayout>
        </Layout>
    )
}
